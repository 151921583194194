import React from 'react';
import { ProductCondition, ProductMax } from '../../../general/types/types';

interface ProductSchemaProps {
  product: ProductMax;
  shippingPrice: string;
  currency: string;
}

const ProductSchema: React.FC<ProductSchemaProps> = ({ product, shippingPrice, currency }) => {
  if (!product) return null;

  const productSchema = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: product.name,
    image: product.image || product.images.map((img) => img.url),
    description: product.description,
    sku: product.id,
    brand: {
      '@type': 'Brand',
      name: product.manufacturer,
    },
    offers: {
      '@type': 'Offer',
      url: window.location.href,
      priceCurrency: 'USD',
      price: product.price.toString(),
      itemCondition: `https://schema.org/${product.condition === ProductCondition.New ? 'NewCondition' : 'UsedCondition'}`,
      availability:
        product.amount > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
      seller: {
        '@type': 'Organization',
        name: 'AsicXchange',
      },
      shippingDetails: {
        '@type': 'OfferShippingDetails',
        shippingRate: {
          '@type': 'MonetaryAmount',
          value: shippingPrice,
          currency: currency,
        },
      },
      hasMerchantReturnPolicy: {
        '@type': 'MerchantReturnPolicy',
        returnPolicyCategory: 'https://schema.org/Returnable',
        applicableCountry: 'US', // ISO-код страны
      },
    },
  };

  return <script type="application/ld+json">{JSON.stringify(productSchema)}</script>;
};

export default ProductSchema;
