export interface SEO {
  metaTitle?: string;
  metaDescription?: string;
}

export interface Indexing {
  indexing?: boolean;
  priority?: number;
  changefreq?: string;
}
export interface Route extends Indexing, SEO {
  path: string;
}

export interface ClientRoutes {
  home: Route;
  unsubscribe: Route;
  aboutUs: Route;
  products: Route;
  productsRetail: Route;
  productsWholesale: Route;
  product: Route;
  productRetailCategory: Route;
  retail: Route;
  wholesale: Route;
  productWholesaleCategory: Route;
  productRetailCategoryTable: Route;
  productWholesaleCategoryTable: Route;
  productDetails: Route;
  pool: Route;
  hosting: Route;
  blog: Route;
  blogPost: Route;
  helpCenter: Route;
  helpCenterQA: Route;
  helpCenterFAQ: Route;
  contacts: Route;
  cart: Route;
  cartInformation: Route;
  cartShipping: Route;
  cartPayment: Route;
  profile: Route;
  profileWishlist: Route;
  profileOrders: Route;
  compare: Route;
  cookiesPolicy: Route;
  publicOffer: Route;
  userAgreement: Route;
  emptyPage: Route;
  notFound: Route;
  stub: Route;
}

export interface AdminRoutes {
  home: Route;
  adminPanel: Route;
  authorization: Route;
  profile: Route;
  orders: Route;
  order: Route;
  products: Route;
  productCategories: Route;
  product: Route;
  productEdit: Route;
  articles: Route;
  article: Route;
  clients: Route;
  articleEdit: Route;
  subscribers: Route;
  newsletterWorkshop: Route;
  recipientList: Route;
  client: Route;
  admins: Route;
  adminEmails: Route;
  messages: Route;
  messagesList: Route;
  contactUs: Route;
  faq: Route;
  qa: Route;
  bloggersAndCompanies: Route;
  referralCodes: Route;
  clientTracking: Route;
  contacts: Route;
  auditLogs: Route;
  shippingInfo: Route;
  notFound: Route;
}

export const routesClient: ClientRoutes = {
  home: {
    path: '/',
    indexing: true,
    priority: 1.0,
    changefreq: 'daily',
    metaTitle: 'ASIC Miners for Sale | Worldwide Shipping | AsicXchange',
    metaDescription:
      'Best deals on high-performance ASIC miners & hosting. Affordable, top-tier equipment with warranty. Worldwide shipping, Expert Team & Support.',
  },
  unsubscribe: { path: '/unsubscribe', indexing: false },
  aboutUs: {
    path: '/about',
    indexing: true,
    priority: 1.0,
    changefreq: 'daily',
    metaTitle: 'About AsicXchange: Leading Crypto Mining Equipment Supplier',
    metaDescription:
      'Founded in 2018 to supply top-notch crypto mining equipment. $100M in sales, global impact, and dedication to eco-friendly mining and our customers.',
  },
  products: { path: '/products', indexing: false },
  productsRetail: {
    path: '/products/retail',
    indexing: true,
    priority: 0.8,
    changefreq: 'daily',
    metaTitle: 'Shop ASIC Miners – Buy Retail | AsicXchange',
    metaDescription:
      'Purchase high-quality ASIC miners for personal mining operations. Retail ASIC miners with warranty and worldwide shipping.',
  },
  productsWholesale: {
    path: '/products/wholesale',
    indexing: true,
    priority: 0.8,
    changefreq: 'daily',
    metaTitle: 'Shop ASIC Miners – Buy Wholesale | AsicXchange',
    metaDescription:
      'Purchase high-quality ASIC miners for personal mining operations. Wholesale ASIC miners with warranty and worldwide shipping.',
  },
  product: {
    path: '/product',
    indexing: true,
    priority: 0.8,
    changefreq: 'daily',
    metaTitle: '{Product Name} – Buy Now | AsicXchange',
    metaDescription:
      'Buy {Product Name} (Condition: {Condition}, Algorithm: {Algorithm}, Hashrate: {Hashrate}, Energy Efficiency: {Energy Efficiency}, Power Consumption: {Power Consumption}). Perfect for professional Bitcoin mining. Worldwide shipping and warranty available.',
  },
  productRetailCategory: {
    path: '/products/retail/:category',
    indexing: false,
  },
  retail: { path: '/retail', indexing: false },
  wholesale: { path: '/wholesale', indexing: false },
  productWholesaleCategory: {
    path: '/products/wholesale/:category',
    indexing: false,
  },
  productRetailCategoryTable: {
    path: '/products/retail/:category/table',
    indexing: false,
  },
  productWholesaleCategoryTable: {
    path: '/products/wholesale/:category/table',
    indexing: false,
  },
  productDetails: { path: '/product/:url', indexing: false },
  pool: {
    path: '/pool',
    indexing: true,
    priority: 0.6,
    changefreq: 'daily',
    metaTitle: 'Join Our Bitcoin Mining Pool – Efficient & Profitable | AsicXchange',
    metaDescription:
      'Connect to our Bitcoin mining pool for higher profitability and seamless operation. Trusted by miners worldwide for reliability and expert support.',
  },
  hosting: {
    path: '/hosting',
    indexing: true,
    priority: 0.6,
    changefreq: 'daily',
    metaTitle: 'Secure Hosting for ASIC Miners | AsicXchange',
    metaDescription:
      'Hosting solutions for ASIC miners. Ensure uptime, efficiency, and security at our state-of-the-art facilities. Contact us to start mining hassle-free.',
  },
  blog: {
    path: '/blog',
    indexing: true,
    priority: 0.6,
    changefreq: 'daily',
    metaTitle: 'Crypto Mining Insights & News | AsicXchange Blog',
    metaDescription:
      'Stay updated with the latest trends in cryptocurrency mining. Explore expert tips, equipment reviews, and sustainability strategies.',
  },
  blogPost: { path: '/blog/:url', indexing: false },
  helpCenter: { path: '/help-center', indexing: false },
  helpCenterQA: {
    path: '/help-center/qa',
    indexing: true,
    priority: 0.6,
    changefreq: 'daily',
    metaTitle: 'Help Center – Your Mining Questions Answered | AsicXchange',
    metaDescription:
      'Find answers to common questions about ASIC miners, hosting, and our services. Expert guidance to help you start mining with confidence.',
  },
  helpCenterFAQ: {
    path: '/help-center/faq',
    indexing: true,
    priority: 0.6,
    changefreq: 'daily',
    metaTitle: 'Frequently Asked Questions – ASIC Mining | AsicXchange',
    metaDescription:
      'Answers to popular questions about ASIC miners, hosting, and our services. Explore detailed solutions and tips for crypto mining.',
  },
  contacts: {
    path: '/contact',
    indexing: true,
    priority: 1.0,
    changefreq: 'daily',
    metaTitle: 'Contact AsicXchange – Get in Touch with Us',
    metaDescription:
      'Need assistance? Contact our team for expert advice on ASIC miners, hosting, and mining pools. We’re here to help!',
  },
  cart: {
    path: '/cart',
    indexing: true,
    priority: 0.6,
    changefreq: 'daily',
    metaTitle: 'Cart – Review Your Order at AsicXchange',
    metaDescription:
      'Check your items, update quantities, and complete your purchase for premium ASIC miners and hosting services. Secure and fast checkout awaits!',
  },
  cartInformation: {
    path: '/cart/information',
    indexing: false,
  },
  cartShipping: {
    path: '/cart/information/shipping',
    indexing: false,
  },
  cartPayment: {
    path: '/cart/information/shipping/payment',
    indexing: false,
  },
  profile: { path: '/profile', indexing: false },
  profileWishlist: {
    path: '/profile/wishlist',
    indexing: false,
  },
  profileOrders: { path: '/profile/orders', indexing: false },
  compare: { path: '/compare', indexing: false },
  cookiesPolicy: {
    path: '/cookies-policy',
    indexing: true,
    priority: 0.6,
    changefreq: 'daily',
    metaTitle: 'Cookie Policy – How We Use Cookies | AsicXchange',
    metaDescription:
      'Learn how AsicXchange uses cookies to enhance user experience. Read our policy on tracking technologies and data privacy.',
  },
  publicOffer: {
    path: '/public-offer',
    indexing: true,
    priority: 0.6,
    changefreq: 'daily',
    metaTitle: 'Public Offer Agreement | AsicXchange',
    metaDescription:
      'Review our public offer agreement outlining the terms of purchasing and using AsicXchange services and products.',
  },
  userAgreement: {
    path: '/user-agreement',
    indexing: true,
    priority: 0.6,
    changefreq: 'daily',
    metaTitle: 'User Agreement – Terms & Conditions | AsicXchange',
    metaDescription:
      'Read our user agreement to understand your rights and responsibilities when using AsicXchange services.',
  },
  emptyPage: { path: '/*', indexing: false },
  notFound: {
    path: '/404',
    indexing: true,
    priority: 0.6,
    changefreq: 'daily',
    metaTitle: 'Page Not Found | AsicXchange',
    metaDescription:
      'The page you are looking for could not be found. Return to AsicXchange for the best ASIC miner deals and services.',
  },
  stub: { path: '#', indexing: false },
};

export const routesAdmin: AdminRoutes = {
  home: { path: '/' },
  adminPanel: { path: '/admin' },
  profile: { path: '/profile' },
  orders: { path: '/orders' },
  order: { path: '/orders/:id' },
  products: { path: '/products' },
  productCategories: { path: '/products/categories' },
  product: { path: '/products/product' },
  productEdit: { path: '/products/product/:id' },
  articles: { path: '/articles' },
  article: { path: '/articles/article' },
  articleEdit: { path: '/articles/article/:id' },
  clients: { path: '/clients' },
  subscribers: { path: '/clients/subscribers' },
  newsletterWorkshop: { path: '/clients/newsletter_workshop' },
  recipientList: { path: '/clients/recipient_list' },
  client: { path: '/clients/:id' },
  admins: { path: '/admins' },
  adminEmails: { path: '/admins/emails' },
  messages: { path: '/messages' },
  messagesList: { path: '/messages' },
  contactUs: { path: '/messages/contact-us' },
  faq: { path: '/help-center' },
  qa: { path: '/help-center/qa' },
  bloggersAndCompanies: { path: '/referral-program' },
  referralCodes: { path: '/referral-program/referral-codes' },
  clientTracking: { path: '/referral-program/client-tracking' },
  contacts: { path: '/contacts' },
  auditLogs: { path: '/audit-logs' },
  shippingInfo: { path: '/shipping-info' },
  authorization: { path: '/*' },
  notFound: { path: '/*' },
};
