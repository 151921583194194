import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { routesClient } from '../../../general/routes';
import { isPrerendering } from '../../..';

interface MetaProps {
  title: string | null;
  content: string;
  metaName: string;
}

interface DynamicMetaTagProps {
  isIndexing?: boolean;
}

const DynamicMetaTag: React.FC<DynamicMetaTagProps> = ({ isIndexing = true }) => {
  const location = useLocation();

  // Функция для получения метаинформации динамически
  const getMeta = (): MetaProps | null => {
    for (const routeKey in routesClient) {
      const route = routesClient[routeKey as keyof typeof routesClient];
      if (route.indexing && route.path === location.pathname) {
        return {
          title: route.metaTitle ?? null,
          content: route.metaDescription ?? '',
          metaName: 'description',
        };
      }
    }
    return null; // Если маршрут не найден
  };

  const meta = getMeta();

  return (
    meta && (
      <Helmet>
        {meta.title && <title>{`${meta.title}${isPrerendering ? 'REACT_SNAP' : ''}`}</title>}
        <meta name={meta.metaName} content={meta.content} />
        {isIndexing ? (
          <meta name="robots" content="index, follow" />
        ) : (
          <meta name="robots" content="noindex, nofollow" />
        )}
      </Helmet>
    )
  );
};

export default DynamicMetaTag;
