import React from 'react';
import { Helmet } from 'react-helmet';
import { routesClient } from '../../../general/routes';
import { isPrerendering } from '../../..';
import { ProductMax } from '../../../general/types/types';

interface ProductMetaTagProps {
  product: ProductMax;
}

const ProductMetaTag: React.FC<ProductMetaTagProps> = ({ product }) => {
  const route = routesClient.product;

  const metaTitle = route.metaTitle?.replace('{Product Name}', product.name) ?? '';
  const metaDescription =
    route.metaDescription
      ?.replace('{Product Name}', product.name)
      .replace('{Condition}', product.condition ?? '')
      .replace('{Algorithm}', product.algorithm ?? '')
      .replace('{Hashrate}', product.hashrate ?? '')
      .replace('{Energy Efficiency}', product.energyEfficiency?.toString() ?? '')
      .replace('{Power Consumption}', product.energyConsumption?.toString() ?? '') ?? '';

  return (
    <Helmet>
      {metaTitle && <title>{`${metaTitle}${isPrerendering ? 'REACT_SNAP' : ''}`}</title>}
      <meta name="description" content={metaDescription} />
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
};

export default ProductMetaTag;
