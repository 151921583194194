import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useNormalizePath = (exceptions = []) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isNavigating = useRef(false); // Чтобы предотвратить повторный вызов `navigate`

  useEffect(() => {
    // Удаляем двойные слэши и конечный слэш (кроме корневого `/`)
    const normalizedPath = location.pathname
      .replace(/\/{2,}/g, '/') // Убираем дублирующиеся слэши
      .replace(/\/+$/, ''); // Убираем конечный слэш

    // Проверяем исключения
    const isException = exceptions.some((path) => normalizedPath.startsWith(path));

    // Навигация, если путь не нормализован
    if (location.pathname !== normalizedPath && !isException && !isNavigating.current) {
      isNavigating.current = true; // Устанавливаем флаг
      navigate(normalizedPath + location.search, { replace: true });
    } else {
      isNavigating.current = false; // Сбрасываем флаг
    }
  }, [location, navigate, exceptions]);
};

export default useNormalizePath;
